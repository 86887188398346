<template>
  <Header :layout="layout" :menu="menuHeader ?? {}" />
  <div>
    <slot />
  </div>
  <Footer :layout="layout" :menu="menuFooter ?? {}" />
</template>
<script lang="ts" setup>
import './main.css'
import { toHead } from 'vue-datocms'
import { models as __models } from '~/integrations/dato/dato.ts'
import Header from '~/components/header/header.vue'
import Footer from '~/components/footer/footer.vue'

const { locale } = useI18n()
const metasData = await __models.metas.getMetas()

const menuHeader = await __models.menus.getMenu('menu_header', locale.value),
  menuFooter = await __models.menus.getMenu('menu_footer', locale.value),
  layout = await __models.layout.getLayout('main')

useHead(() => {
  return toHead(metasData?.faviconMetaTags || [])
})
</script>
